import { FunctionComponent, useCallback, useRef, useState } from "react"
import InputPatterns, { isMatchingPattern } from "../utils/constants/input-patterns"
import CustomSelectBox from "./custom-select-box"

export enum FieldInputType{
    TEXT = 'text',
    PASSWORD = 'password',
    SELECT_BOX = 'selectbox',
    NUMBER = 'number',
    PHONE = 'tel',
    FILE = 'file'
}

export enum FieldValidity{
    VALID = 'valid',
    EMPTY = 'empty',
    INVALID = 'invalid'
}

export type Field  = {
    type? : FieldInputType
    label? : string
    id? : string
    initialValue? : string
    placeholder? : string
    icon? : string
    required? : boolean
    readonly? : boolean
    pattern? : InputPatterns | string
    onChange? : (field : {name : string, value : any}) => void

    onEmptyErrorMessage? : string
    onInvalidErrorMessage? : string
    renderFieldComponent? : FunctionComponent<Field & {name : string, validity? : FieldValidity, hasError? : boolean}>

    extraData? : any
}

type props = {
    ref? : HTMLElement
    fields : {[x : string] : Field}
    renderFieldComponent? : FunctionComponent<Field & {name : string, validity? : FieldValidity, hasError? : boolean}>
    onChange? : (field : {name : string, value : any}) => void
    onSubmit? : ( fields : {[x : string] : any}) => void
    className? : string
    SubmitButton? : any
    children? : any
}

export function InputFieldTemplate1( props : Field & {name : string, validity? : FieldValidity, hasError? : boolean} ){
    return (
        <div className={`w-full ${!props.extraData?.fullWidth ? "sm:w-[48%]" : ""} mt-4`}>
            <div className="w-full">
                <div className="flex items-center flex-wrap w-full gap-2">                       
                    <label className="text-gray-500" htmlFor={props.id}>{props.label}</label>
                    { props.hasError && (props.validity === FieldValidity.EMPTY) && <p className='text-red-500 text-xs mt-1'>{props.onEmptyErrorMessage}</p>}
                </div>
                <input  
                    className='rounded-md p-2 h-12 w-full border'
                    id={props.id}
                    type={props.type} 
                    name={props.name} 
                    onChange={e => props.onChange && props.onChange({name : e.target.name, value : e.target.value})}
                    min={ props.extraData?.min } 
                    max={ props.extraData?.max } 
                    placeholder={props.placeholder}
                    defaultValue={props.initialValue}
                    readOnly={props.readonly} 
                />
            </div>
            { props.hasError && (props.validity === FieldValidity.INVALID) && <p className='text-red-500 text-xs mt-1 '>{props.onInvalidErrorMessage}</p> }
        </div>
    )
}

export function InputFieldRadio( props : Field & {name : string, validity? : FieldValidity, hasError? : boolean} ){
    return (
        <div className={`w-full ${!props.extraData?.fullWidth ? "sm:w-[48%]" : ""} mt-4`}>
            <div className="w-full">
                <div className="flex flex-wrap w-full gap-2">                       
                    <label className="text-gray-500" htmlFor={props.id}>{props.label}</label>
                    { props.hasError && (props.validity === FieldValidity.EMPTY) && <p className='text-red-500 text-xs mt-1'>{props.onEmptyErrorMessage}</p>}
                </div>
                { props.extraData?.options?.map( (option : any) => (
                    <div className="flex gap-4">
                        <input type="radio" id={props.id} />
                    </div>
                )) }
            </div>
            { props.hasError && (props.validity === FieldValidity.INVALID) && <p className='text-red-500 text-xs mt-1 '>{props.onInvalidErrorMessage}</p> }
        </div>
    )
}

export function InputFieldTextArea( props : Field & {name : string, validity? : FieldValidity, hasError? : boolean} ){
    return (
        <div className="w-[calc(100%-.5rem)]">
            <div className="flex items-center  flex-wrap w-full gap-2">                       
                <label className="text-gray-500" htmlFor="preface">{props.label}</label>
                { props.hasError && <p className='text-red-500 text-xs mt-1'>{ (props.validity === FieldValidity.EMPTY) ? props.onEmptyErrorMessage : props.onInvalidErrorMessage}</p>}
            </div>
            <textarea 
                onChange={e => props.onChange && props.onChange({name : e.target.name, value : e.target.value})}
                name={props.name} 
                id={props.id} 
                rows={5} 
                placeholder={props.placeholder} // 'entrée une description du livre (aumoins 500 characters)'
                defaultValue={props.initialValue}
                className="p-2 w-full border rounded-md"
            />
        </div>
    )
}

export function InputFieldCustomSelect( props : Field & {name : string, validity? : FieldValidity, hasError? : boolean} ){
    return(
        <div className={`w-full ${!props.extraData?.fullWidth ? "sm:w-[48%]" : ""} mt-4`}>
            <div className="flex items-center flex-wrap w-full gap-2">
                <label className="text-gray-500" htmlFor={props.id}>{props.label}</label>
                { props.hasError && (
                    <p className='text-red-500 text-xs mt-1'> { props.validity === FieldValidity.EMPTY ? props.onEmptyErrorMessage : props.onInvalidErrorMessage } </p>
                )}
            </div>
            <CustomSelectBox
                id={props.id}
                className="bg-white"
                name={props.name}
                placeholder={props.placeholder}
                defaultValue={props.initialValue}
                options={props.extraData?.options}
                onChange={ option => props.onChange && props.onChange({name : props.name, value : option.value!} ) }
            />
        </div>
    )
}

export function ImagePicker(props : Field & {name : string, validity? : FieldValidity, hasError? : boolean}) {
    const inputRef = useRef<HTMLInputElement | null>(null)
    const imgRef = useRef<HTMLImageElement | null>(null)

    const handleFileInputChange = useCallback( (e : any) => {
        const {files} = e.target
        if(files){

            const file = files[0]
            if(file){
                imgRef.current!.src = URL.createObjectURL(file)
                const fileReader = new FileReader()    
                fileReader.onloadend = () => props.onChange && props.onChange({name : props.name, value : fileReader.result})
                fileReader.readAsDataURL(file)
            }
        }
        
    }, [props])

    return (
        <div className="w-full">
            <div className="mt-4 relative mx-auto w-40 h-40 border border-slate-200 rounded-md shadow">
                <img ref={imgRef} className="bg-slate-200 object-cover object-center w-full h-full rounded-md" src={props.extraData?.emptyImageView || "/images/abstracts/avatar.svg"} alt="candidate img" />
                {props.hasError && (props.validity === FieldValidity.EMPTY) && <p className="block text-xs text-red-600">{props.onEmptyErrorMessage}</p>}
                <input id={props.id} ref={inputRef} onChange={handleFileInputChange} className="hidden" type="file" name={props.name} />
                <div onClick={() => inputRef.current?.click()} className="cursor-pointer absolute right-0 bottom-0 translate-x-1/2 translate-y-1/2 flex justify-center items-center w-8 h-8 rounded-full shadow-lg bg-primary hover:bg-secondary text-white text-xs fas fa-camera" />
            </div>

        </div>
    )
};


export default function MyForm({ ref, fields, onChange, onSubmit, className, renderFieldComponent: RenderFieldComponent = InputFieldTemplate1, children} : props){
    const formRef = useRef<HTMLFormElement | null>(null)
    const [states, setStates] = useState( { fields  })
    const formDatas = useRef( (() => {
        let datas : {[x : string] : string} = {}
        Object.entries(fields).forEach( ([fieldName, field]) => {
            datas[fieldName] = field.initialValue || ''
        })

        return datas
    })() ) 

    const validateField = useCallback( (field : Field, currentValue : string) => {
        
        if(field.required){
            if(!currentValue) return FieldValidity.EMPTY
        }

        if( field.pattern && !isMatchingPattern(currentValue, field.pattern) ){
            return FieldValidity.INVALID
        }

        return FieldValidity.VALID
    }, [])
    
    const handleSubmit = useCallback( (e : any) => {
        e.preventDefault()

        let anyError = false
        const newDatas : any = {}
        Object.entries(states.fields).forEach( ([fieldName, field]) => {
            const validity = validateField(field, formDatas.current[fieldName])
            newDatas[fieldName] = {
                ...fields[fieldName],
                initialValue : formDatas.current[fieldName], 
                validity, 
                hasError : validity !== FieldValidity.VALID 
            }
            anyError ||= validity !== FieldValidity.VALID
        })

        setStates( prev => ({...prev, fields : newDatas}))
        if(anyError){
            formRef.current!.scrollIntoView( { block : "start", behavior : "smooth", inline : "start" } )
        }
        !anyError && onSubmit && onSubmit(formDatas.current)

    }, [fields, onSubmit, states.fields, validateField])
    
    const handleChange = useCallback( (field : {name : string, value : any}) => {
        formDatas.current = {...formDatas.current, [field.name] : field.value}
        onChange && onChange(field)
    }, [onChange])

    return (
        <form
            ref={formRef} 
            onSubmit={handleSubmit}
            className={`text-lg ${className}`}
            
            onKeyDown={ e => (e.key === 'Enter') && handleSubmit(e) }
        >
            { 
                Object.entries(states.fields)
                .map( ([name, field], index) => (
                    field.renderFieldComponent ? <field.renderFieldComponent key={index} {...field} onEmptyErrorMessage="Champ obligatoire" onInvalidErrorMessage="format invalid!" name={name} onChange={handleChange} />: <RenderFieldComponent key={index} {...field} onEmptyErrorMessage="Champ obligatoire" name={name} onChange={handleChange} />
                )) 
            }
            
            {children}
        </form>
    )
}