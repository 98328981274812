import {useState, useCallback} from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useAuth } from '../../hooks'
import HttpStatusCode from '../../utils/constants/http-status-code'
import InputPatterns from '../../utils/constants/input-patterns'
import Button from '../button'
import MyForm, { FieldInputType } from '../my-form'

type States = {
    isLoading? : boolean
    errorStatusCode? : number
}

export default function ResetPassword(){
    const { resetPasswordRequest } = useAuth()
    const [states, setStates] = useState<States>({})
    const navigate = useNavigate()

    const Notification = useCallback( () => {
        if(states.errorStatusCode === HttpStatusCode.CANT_REACH){
            return(
                <div className={`relative flex items-center gap-x-2 max-w-sm py-2 px-4 bg-red-300 rounded-sm `}>
                    <button 
                        onClick={() => setStates( prev => ({...prev, errorStatusCode : undefined}) )}
                        className={`absolute -top-3 right-1 hover:scale-105 flex items-center justify-center w-6 h-6 rounded-full  text-xs fas fa-close bg-red-400 shadow-md hover:text-white`} 
                    />
                    <i className="fas fa-bug text-2xl" />
                    <p className="text-xs">Pas de connexion internet!</p>
                </div>
            )
        }else if(states.errorStatusCode === HttpStatusCode.SERVER_ERROR){
            return(
                <div className={`relative flex items-center gap-x-2 max-w-sm py-2 px-4 bg-red-300 rounded-sm `}>
                    <button 
                        onClick={() => setStates( prev => ({...prev, errorStatusCode : undefined}) )}
                        className={`absolute -top-3 right-1 hover:scale-105 flex items-center justify-center w-6 h-6 rounded-full  text-xs fas fa-close bg-red-400 shadow-md hover:text-white`} 
                    />
                    <i className="fas fa-bug text-2xl" />
                    <p className="text-xs">Oops! réesayez svp!</p>
                </div>
            )
        }else if(states.errorStatusCode === HttpStatusCode.LOCKED){
            return(
                <div className={`relative flex items-center gap-x-2 max-w-sm py-2 px-4 bg-orange-300 rounded-sm `}>
                    <button 
                        onClick={() => setStates( prev => ({...prev, errorStatusCode : undefined}) )}
                        className={`absolute -top-3 right-1 hover:scale-105 flex items-center justify-center w-6 h-6 rounded-full  text-xs fas fa-close bg-orange-400 shadow-md hover:text-white`} 
                    />
                    <i className="fa fa-warning text-2xl" />
                    <p className="text-xs">Vous devez activer votre compte! <br/> consulter votre boite-mail!</p>
                </div>
            )
        }else if( states.errorStatusCode === HttpStatusCode.UNAUTHORIZED ){
            return(
                <div className={`relative flex items-center gap-x-2 max-w-sm py-2 px-4 bg-red-300 rounded-sm `}>
                    <button 
                        onClick={() => setStates( prev => ({...prev, errorStatusCode : undefined}) )}
                        className={`absolute -top-3 right-1 hover:scale-105 flex items-center justify-center w-6 h-6 rounded-full  text-xs fas fa-close bg-red-400 shadow-md hover:text-white`} 
                    />
                    <i className="fas fa-info text-2xl" />
                    <p className="text-xs">Informtions incorrectes!</p>
                </div>
            )
        }else{
            return <></>
        }
    }, [states.errorStatusCode])

    const onSubmit = useCallback( (username : string) => {
        
        setStates( prev => ({...prev, isLoading : true, errorStatusCode : undefined}))

        resetPasswordRequest(username)
        .then( () => navigate("/auth/update-password", { state : {username}}))
        .catch( err => setStates( prev => ({...prev, errorStatusCode : (err.response?.data || {statusCode : HttpStatusCode.CANT_REACH}).statusCode})) )
        .finally( () => setStates( prev => ({...prev, isLoading : false})) )
    }, [navigate, resetPasswordRequest])

    return(
        <>
            <div className="fas fa-refresh -mt-16 mb-8 mx-auto w-24 h-24 flex justify-center items-center bg-white text-5xl shadow rounded-full" />
            <Notification />
            
            <MyForm
                className=' max-w-sm' 
                fields={{
                    username : {
                        icon : 'fas fa-at',
                        type : FieldInputType.TEXT,
                        placeholder : 'adresse email de connexion',
                        id : 'username',
                        required : true,
                        pattern : InputPatterns.EMAIL,
                        onEmptyErrorMessage : 'champ obligatoire',
                        onInvalidErrorMessage : 'adresse email invalid!',
                        extraData : { fullWidth : true},                   
                    },
                } }

                onSubmit={fields => onSubmit(fields.username)}
            >
                <Button 
                    className='w-full mt-4 flex items-center justify-center gap-2' 
                    disabled={states.isLoading}
                >
                    <span>réinitialiser</span>
                    { states.isLoading ? <span className='fa-solid fa-circle-notch animate-spin' /> : <i className="fas fa-refresh" />  }
                </Button>

                <div className="text-center my-2 divide-y-8 divide-transparent ">
                    <Link to='/auth/signin' className="underline underline-offset-2 text-primary">se connecter</Link>
                </div>
            </MyForm>
        </>
    )
}