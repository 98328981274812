import { Link } from "react-router-dom"

type props = {
    children? : any
    className? : string
    style? : any
    size? : 'sm' | 'md' | 'lg'
    bgColor? : string
    hoverBgColor? : string
    textColor? : string
    hoverTextColor? : string
    onClick? : () => void
    link? : string
    disabled? : boolean
}
export default function Button({children,link, disabled, className = '', style, bgColor = 'primary', hoverBgColor = 'primary-deep', textColor = 'white', hoverTextColor = 'white', onClick} : props){
    return link ? (
        <Link to={link} className={`px-4 py-2 bg-primary text-white hover:bg-primary-deep rounded-[.25rem]  ${className}`} style={style}>
            {children}
        </Link>
    ) : (
        <button 
            className={`${className} px-4 py-2 bg-primary text-white hover:bg-secondary rounded-[.25rem]  disabled:pointer-events-none disabled:bg-opacity-80`} style={style}
            onClick={ () => onClick && onClick()}
            disabled={disabled}
        >
            {children}
        </button>
    )
}