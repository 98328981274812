type props = {
    errorText? : string
    reloadText? : string
    callback? : () => void
}
export default function ErrorComponent({errorText, reloadText, callback = () => {}} : props){

    return (
        <div className="text-xs flex-1 flex flex-col items-center gap-2 py-8 sm:-12">
            <i className="fas fa-bug text-xl sm:text-2xl md:text-6xl text-slate-800" />
            <p className=" max-w-sm text-center">{errorText}</p>
            <button onClick={callback} className="py-2 px-3 text-slate-500 hover:bg-slate-900 border border-slate-800">cliquez pour recharger</button>
        </div>
    )
}