import { Link } from "react-router-dom";
import { MdOutlinePlaylistRemove } from "react-icons/md";
import { useEffect } from "react";
type props = {
    /**
     * default to edit of current path
     */
    to? : string
    text? : string
    showAddButton? : boolean
}
export default function EmptyList({showAddButton, to = "", text} : props){

    useEffect( () => {

    }, [])
    console.log("empty list")
    return(
        <div className="text-xs flex-1 flex flex-col items-center gap-2 py-8">
            <MdOutlinePlaylistRemove />
            <p className="text-red-800 text-6xl">{text}</p>
            { showAddButton && 
                <Link to={to} className="fas fa-add w-8 h-8 bg-primary hover:bg-primary-deep text-white rounded-full flex justify-center items-center" />
            }
        </div>
    )
}