import { useMemo, useEffect } from 'react';
import { createContext, ReactNode, useState } from "react"
import { LoginResponse, User } from "../models"
import Constants from "../utils/constants/constants"
import { deleteFromLocalStorage, getFromLocalStorage, saveToLocalStorage } from "../utils/helpers/local-storage"

export type AuthContextProps = {
    user?: User,
    accessToken? : string,
    setAuthSession : React.Dispatch<React.SetStateAction<any>>,
}
export const AuthContext = createContext<AuthContextProps>({ setAuthSession : () => {}})

export default function AuthContextProvider ( {children} : {children : ReactNode}) {

    const [authSession, setAuthSession] = useState<LoginResponse | undefined>(getFromLocalStorage<LoginResponse>( Constants.AUTHENTICATED_USER ))
    const user = useMemo( () =>  authSession?.user, [authSession])
    const accessToken  = useMemo( () =>  authSession?.accessToken, [authSession] )
    
    useEffect( () => {
        if(authSession) {
            saveToLocalStorage(Constants.AUTHENTICATED_USER, authSession)
        }else {
            deleteFromLocalStorage(Constants.AUTHENTICATED_USER)
        }
    }, [authSession])

    return(
        <AuthContext.Provider value={{
            user, 
            accessToken,
            setAuthSession
        }}>
            {children}
        </AuthContext.Provider>
    )
}