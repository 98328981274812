import { BrowserRouter, Routes, Route } from "react-router-dom"
import GlobalContextProvider from "./contexts/global";
import { Authentication, PageNotFound, Dashboard } from "./pages";

function App() {
  return (
    <GlobalContextProvider>
      <BrowserRouter >
        <Routes>
          <Route path='' element={<Dashboard />} />
          <Route path='auth/*' element={<Authentication />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
    </GlobalContextProvider>
  );
}

export default App;
