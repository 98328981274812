import { createContext, ReactNode} from "react"
import AuthContextProvider from "./auth"

export type GlobalContextProps = {
    
}
export const GlobalContext = createContext<GlobalContextProps>({setAuthSession : () => undefined})

export default function GlobalContextProvider ( {children} : {children : ReactNode}) {

    return(
        <GlobalContext.Provider value={{}}>
            <AuthContextProvider>
                {children} 
            </AuthContextProvider>
        </GlobalContext.Provider>
    )
}
