import { useCallback, useContext } from "react";
import useApi from "./api/use-api";
import UpdatePasswordDto from "../models/user/update-password";
import { User, LoginResponse } from "../models";
import { AuthContext } from "../contexts/auth";

export default function useAuth(){

    const {user, accessToken, setAuthSession} = useContext(AuthContext)

    const {api, addRequestInterceptor} = useApi('auth')

    const signup = useCallback( async (user : User & {password? : string}) => api.post<User>('signup', {...user, role : 'merchnt'}) , [api])

    const askForNewActivationLink = useCallback( async (email : string) => api.patch('new-activation-link', {}, { headers : { 'email' : email } }) , [api])
    
    const askForNewResetCode = useCallback( async (email : string) => api.patch('new-reset-password-code', {}, {headers : { 'email' : email }}) , [api])

    const signin = useCallback( (loginRequest : {username : string, password : string}) => api.post<LoginResponse>('signin', loginRequest ).then( response => setAuthSession( response.data)), [api, setAuthSession ])

    const logout = useCallback( () => setAuthSession(undefined), [setAuthSession])

    const resetPasswordRequest = useCallback( (username : string) => api.patch('reset-password-request', {}, { headers : { 'email' : username} }) , [api])

    const updatePasswordRequest = useCallback( (updatePasswordDto : UpdatePasswordDto) => api.post('update-password', updatePasswordDto), [api])

    const refreshToken = useCallback( async () => {
        await api.head<string>('refresh-session', { headers : { Authorization : `Bearer ${accessToken}`} })
        .then( ({data}) => setAuthSession( (prev: any) => ({...prev, token : data}) ) )
    }, [accessToken, setAuthSession, api])

    const getUserFullname = useCallback( () => user ? `${user.first} ${user.last}` : "" , [user])

    return {
        user, 
        getUserFullname,
        accessToken, 
        signup,
        signin, 
        askForNewActivationLink, 
        askForNewResetCode, 
        logout, 
        resetPasswordRequest, 
        updatePasswordRequest, 
        refreshToken
    }
}