import { useState, useMemo, useCallback } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom'
import HttpStatusCode from '../../utils/constants/http-status-code'
import InputPatterns from '../../utils/constants/input-patterns'
import Button from '../button'
import MyForm, { FieldInputType } from '../my-form'
import { useAuth } from '../../hooks';

type States = {
    isLoading? : boolean
    errorStatusCode? : number
}

export default function Singin(){
    const {signin} = useAuth()
    const navigate = useNavigate()
    const locationState = useLocation().state as any
    const fromPage = useMemo( () => locationState?.from || '/', [locationState])


    const [states, setStates] = useState<States>({})

    const Notification = useCallback( () => {
        if(states.errorStatusCode === HttpStatusCode.CANT_REACH){
            return(
                <div className={`relative flex items-center gap-x-2 max-w-sm py-2 px-4 bg-red-300 rounded-sm `}>
                    <button 
                        onClick={() => setStates( prev => ({...prev, errorStatusCode : undefined}) )}
                        className={`absolute -top-3 right-1 hover:scale-105 flex items-center justify-center w-6 h-6 rounded-full  text-xs fas fa-close bg-red-400 shadow-md hover:text-white`} 
                    />
                    <i className="fas fa-bug text-2xl" />
                    <p className="text-xs">Pas de connexion internet!</p>
                </div>
            )
        }else if(states.errorStatusCode === HttpStatusCode.SERVER_ERROR){
            return(
                <div className={`relative flex items-center gap-x-2 max-w-sm py-2 px-4 bg-red-300 rounded-sm `}>
                    <button 
                        onClick={() => setStates( prev => ({...prev, errorStatusCode : undefined}) )}
                        className={`absolute -top-3 right-1 hover:scale-105 flex items-center justify-center w-6 h-6 rounded-full  text-xs fas fa-close bg-red-400 shadow-md hover:text-white`} 
                    />
                    <i className="fas fa-bug text-2xl" />
                    <p className="text-xs">Oops! réesayez svp!</p>
                </div>
            )
        }else if(states.errorStatusCode === HttpStatusCode.FORBIDEN){
            return(
                <div className={`relative flex items-center gap-x-2 max-w-sm py-2 px-4 bg-orange-300 rounded-sm `}>
                    <button 
                        onClick={() => setStates( prev => ({...prev, errorStatusCode : undefined}) )}
                        className={`absolute -top-3 right-1 hover:scale-105 flex items-center justify-center w-6 h-6 rounded-full  text-xs fas fa-close bg-orange-400 shadow-md hover:text-white`} 
                    />
                    <i className="fa fa-warning text-2xl" />
                    <p className="text-xs">Vous devez activer votre compte! <br/> consulter votre boite-mail!</p>
                </div>
            )
        }else if( states.errorStatusCode === HttpStatusCode.UNAUTHORIZED ){
            return(
                <div className={`relative flex items-center gap-x-2 max-w-sm py-2 px-4 bg-red-300 rounded-sm `}>
                    <button 
                        onClick={() => setStates( prev => ({...prev, errorStatusCode : undefined}) )}
                        className={`absolute -top-3 right-1 hover:scale-105 flex items-center justify-center w-6 h-6 rounded-full  text-xs fas fa-close bg-red-400 shadow-md hover:text-white`} 
                    />
                    <i className="fas fa-info text-2xl" />
                    <p className="text-xs">Informtions incorrectes!</p>
                </div>
            )
        }else{
            return <></>
        }
    }, [states.errorStatusCode])
    
    const SignupSuccedNotification = useCallback( () => {
        if(locationState?.isgnupSucceed){
            return(
                <div className=' w-full text-gray-500 text-center bg-blue-200 py-2 rounded relative' >
                    <div 
                        className='absolute -top-3 -right-2 flex items-center justify-center w-6 h-6 shadow bg-blue-200 hover:bg-red-400 cursor-pointer rounded-full text-white fas fa-close' 
                        onClick={ () => navigate("") }
                    />
                    <i className="fas fa-info text-4xl text-white" />

                    <p className='text-center text-xs leading-5'>
                        Félicitation! Inscription réussi. <br /> vérifier votre boite-mail <br /> <span className='font-semibold text-primary'>{locationState?.username}</span> <br/>pour l'activer!
                    </p>
                </div>
            )
        }else{
            return <></>
        }
    }, [locationState?.isgnupSucceed, locationState?.username, navigate])

    const PasswordUpdatedNotification = useCallback( () => {
        const {passwordUpdated, username} = locationState || {passwordUpdated : undefined, username : undefined}
        if(passwordUpdated){
            return (
                <div className={`flex items-center gap-x-2 -translate-y-4 max-w-sm py-2 px-4 bg-blue-50 rounded-sm `}>
                    <p className="ml-2 text-xs text-center">
                        Félicitation <span className='font-semibold'>{username}</span> <br />
                        votre mot de passe a bien été mise-à-jour! <br />
                        utlisez le nouveau mot de passe pour vous connecter
                    </p>
                </div>
            )
        }else{
            return <></>
        }
    }, [locationState])
    
    const onSubmit = useCallback( (fields : any) => {

        setStates( prev => ({...prev, isLoading : true, errorStatusCode : undefined}))

        signin(fields)
        .then( () => navigate(fromPage))
        .catch( err => setStates( prev => ( { ...prev, errorStatusCode : err?.response?.data?.statusCode || HttpStatusCode.CANT_REACH }) ) )
        .finally( () => setStates( prev => ({...prev, isLoading : false})) )
    }, [ navigate, fromPage, signin])

    return(
        <>
            <div className="fas fa-user-lock -mt-16 mb-8 mx-auto w-24 h-24 flex justify-center items-center bg-white text-5xl shadow rounded-full" />
            <SignupSuccedNotification />
            <PasswordUpdatedNotification />
            <Notification />
            <MyForm
                className='' 
                fields={{
                    username : {
                        icon : 'fas fa-at',
                        type : FieldInputType.TEXT,
                        placeholder : 'adresse email',
                        id : 'username',
                        required : true,
                        pattern : InputPatterns.EMAIL,
                        extraData : { fullWidth : true},
                        onInvalidErrorMessage : 'adresse email invalid!',
                    },
                    password : {
                        icon : 'fas fa-lock',
                        type : FieldInputType.PASSWORD,
                        placeholder : 'mot de passe',
                        id : 'password',
                        required : true,
                        pattern : InputPatterns.PASSWORD,
                        extraData : { fullWidth : true},
                        onInvalidErrorMessage : 'le mot de passe doit compter aumoins 8 characters et contenir les lettres (minuscule et majuscule) et  aumoins un character special ',
                    }
                } }

                onSubmit={onSubmit}
            >
                <Button 
                    className='w-full mt-4 flex items-center justify-center gap-2' 
                    disabled={states.isLoading}
                >
                    <span>se connecter</span>
                    { states.isLoading ? <span className='fa-solid fa-circle-notch animate-spin' /> : <i className="fas fa-unlock" />  }
                </Button>
                <div className="text-center my-3 divide-y-8 divide-transparent flex flex-col items-center justify-center ">
                    <Link to='/auth/reset-password' className="underline underline-offset-2 text-primary">Mot de passe oublié?</Link>
                    {/* <Link to='/auth/signup' className="underline underline-offset-2 text-primary">Créer un compte auteur</Link> */}
                </div>
            </MyForm>
            
        </>
    )
}