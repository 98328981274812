import { Link } from "react-router-dom";

export default function PageNotFound() {
    return (
        <>
           <section className="">
                <div className="min-h-[calc(100vh-3.5rem-12rem)] grid items-center container mx-auto px-8 py-12 md:px-0 2xl:py-24">
                    {/* <h1 className="text-4xl font-bold text-primary text-center">Page inexhistante</h1> */}
                    <img className="object-contain block mx-auto h-96 md:h-60" src="/images/abstracts/undraw_Page_not_found_re_e9o6-removebg-preview.png" alt="" />
                    <p className="text-secondary text-lg text-center">
                        désolé! la page que vous recherchez n'existe pas ! <br />
                        <Link className="mt-2 w-fit mx-auto flex items-center gap-2 text-white px-6 py-2 bg-primary hover:bg-secondary rounded-md" to="/">
                            <i className="text-xs fas fa-house" />
                            Accueil
                        </Link>
                    </p>
                </div>
           </section>
        </>
    )
};
