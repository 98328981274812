import { Route, Routes } from "react-router-dom";
import { ResetPassword, Singin, UpdatePassword } from "../components";
import PageNotFound from "./page-not-found";

export default function Authentication(){
    
    return (
        <section className="flex justify-center items-center h-screen relative bg-cover bg-center" style={{backgroundImage : "url(/images/fondation-sion-dv-lottery-banner.jpg)"}}>
            <div className="absolute inset-0 bg-secondary bg-opacity-50" />
            <div className="flex justify-center items-center sm:min-h-[calc(100vh-15rem)]">
                <div className="z-10 bg-white shadow p-4 mt-20 mb-16 relative sm:w-[25rem] rounded">
                    <Routes>
                        <Route path="" element={<Singin />}  />
                        <Route path="signin" element={<Singin />} />
                        <Route path="reset-password" element={<ResetPassword />} />
                        <Route path="update-password" element={<UpdatePassword />} />
                        <Route path="*" element={<PageNotFound />} />
                    </Routes>
                </div>
            </div>
        </section>
    )
}