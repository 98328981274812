import { useCallback, useContext, useEffect, useState } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import SearchBox from "../components/search-box"
import { formatDate, getDateFromMongoId } from "../utils/helpers/date-time"
import PageNotFound from "./page-not-found"
import { AuthContext } from '../contexts/auth';
import { useApiCrud, useFetchData } from "../hooks"
import { Booking, ResponseModel } from "../models"
import { EmptyList, ErrorComponent, LoadingComponent } from "../components"


export default function Dashboard(){
    const {user} = useContext(AuthContext)
    const navigate = useNavigate()
    const path = useLocation().pathname

    const { fetchData, states } = useFetchData<Booking>("booking")


    const RenderBooking = useCallback( ( booking : Booking) => (
        <tr>
            <td className="py-1 px-2 text-sm">{booking.userIdentity.name}</td>
            <td className="py-1 px-2 text-sm hidden md:inline">{booking.bookingServiceType}</td>
            <td className="py-1 px-2 text-sm">{booking.bookingEventType}</td>
            <td className="py-1 px-2 text-sm">{formatDate(booking.bookingDate)}</td>
            <td className="py-1 px-2 text-sm hidden md:inline">{booking.bookingDuration}</td>
            <td className="py-1 px-2 text-sm">{booking.payment?.status}</td>
        </tr>
    ), [])

    useEffect( () => {
        !user && navigate("/auth/signin", { state : { from : path } })
        user && fetchData()
    }, [user, navigate, path, fetchData])

    useEffect( () => {
        console.log("isEmpty ==> ", {isEmpty : states.isEmptyList, data : states.data})
    }, [states.isEmptyList, states.data])

    return !user ? <></> : (
        <>
            <section className="hidden sm:block relative bg-cover bg-top h-52 after:absolute after:-z-0 after:inset-0 after:bg-black after:bg-opacity-40" style={{backgroundImage : "url(/images/banner.jpg)"}}>
                <div className=" max-w-3xl mx-auto translate-y-32 relative flex flex-col justify-end z-20 bg-slate-600 py-12 px-6 rounded-lg">
                    <h1 className="text-center text-white text-4xl md:text-6xl md:font-semibold">Booking Listing</h1>
                </div>

            </section>
            <section className="sm:hidden py-12 bg-cover bg-top relative after:absolute after:inset-0 after:bg-black after:bg-opacity-70" style={{backgroundImage : "url(/images/banner.jpg)"}}>
                <h1 className="relative z-10 text-4xl text-center text-slate-100">Booking Listing</h1>
            </section>
            <section className="">
                <div className=" max-w-4xl mx-auto px-2 md:py-24 gap-y-8 gap-x-6 md:gap-x-8 lg:gap-x-14">
                    
                    <div className="py-4 flex justify-between items-center">
                        <div className="">

                        </div>
                        <SearchBox callback={ searchText => console.log("search text ==> ", searchText)} debounceDuration={1500} placeholder="type to search" />

                    </div>
                    <table className="w-full">
                        <thead>
                            <tr className="bg-slate-400 text-slate-50">
                                <th className="p-2 text-left">Booker <span className="hidden md:inline">name</span></th>
                                <th className="p-2 text-left hidden md:inline">Service</th>
                                <th className="p-2 text-left">Envent <span className="hidden md:inline">Type</span></th>
                                <th className="p-2 text-left">Date</th>
                                <th className="p-2 text-left hidden md:inline">Duration</th>
                                <th className="p-2 text-left">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            { states.data?.contents ? states.data?.contents?.map( (booking, index) => <RenderBooking key={index} {...booking} /> )
                            : (
                                <tr className="">
                                    <td colSpan={6}>
                                        { states.isLoading && <LoadingComponent /> }
                                        { states.isError && <ErrorComponent errorText="" callback={ fetchData } />}
                                        { states.isEmptyList && <EmptyList text="No data to display" />}                                    
                                    </td>
                                </tr>
                            )}
                        </tbody>
                        <tfoot>

                        </tfoot>
                    </table>
                </div>
            </section>
        </>
    )
}