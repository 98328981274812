import { useState, useCallback } from "react"
import { ResponseModel, AbstractEntity } from "../models"
import useApiCrud from "./api/use-api-crud"

type States<T> = {
    isLoading?: boolean
    isError?: boolean
    isEmptyList?: boolean
    data?: ResponseModel<T>
}

export default function useFetchData<T extends AbstractEntity>(pathToFetch: string){
    const [states, setStates] = useState<States<T>>({})
    const { findAll } = useApiCrud<T>(pathToFetch)

    const fetchData = useCallback( () => {
        setStates( prev => ({ ...prev, isLoading: true, isError: false}))
        setTimeout( () => {
            findAll({})
            .then( data => setStates( prev => ({ ...prev, data, isEmptyList: data.totalElements === 0})) )
            .catch(() => {
                setStates( prev => ({ ...prev, isError : true}))
            })
            .finally( () => setStates( prev => ({ ...prev, isLoading: false})))
        }, 2000)

    }, [findAll])

    return {
        fetchData,
        states
    }
}
