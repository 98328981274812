import { useContext } from "react";
import useApi from "./use-api";
import { AuthContext } from "../../contexts/auth";

export default function useApiSecured(path : string, baseRoute? : string) {
    const {accessToken} =  useContext(AuthContext)
    const api = useApi(path)

    api.addRequestInterceptor( config => {
        config.headers = { ...config.headers, 'Authorization' : `Bearer ${accessToken}` }

        return config
    })

    return api
};
