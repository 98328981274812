type props = {
    loadingText? : string
    className? : string
    loaderClassName? : string
}
export default function LoadingComponent({loadingText = "loading data", className, loaderClassName} : props){

    return (
        <div className={`${className} flex-1 flex flex-col justify-center items-center p-8 gap-y-4 text-slate-500`}>
            <h3 className=" mx-auto max-w-xl text-center text-sm">{loadingText}</h3>
            <div>
                <i className={`${loaderClassName} fa-solid fa-circle-notch animate-spin`} />
            </div>
        </div>
    )
}